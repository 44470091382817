export function getPlacementContainer({ instanceId, placementType }) {
  const query = buildQuery({ instanceId, placementType });
  const el = document.querySelector(query);
  if (el) {
    return el;
  }
  // biome-ignore lint/nursery/noConsole: log
  console.error(`PurpleDot: No element matching query ${query}`);
  return null;
}

export function getPlacementContainers({ placementType }) {
  const query = buildQuery({ placementType });
  return Array.from(document.querySelectorAll(query));
}

export function getAllPlacementContainers() {
  const query = '[data-purple-dot-placement-type]';
  const els = document.querySelectorAll(query);
  return els;
}

function buildQuery({ instanceId, placementType }) {
  let query = '';
  if (instanceId) {
    query += `[data-purple-dot-instance-id="${instanceId}"]`;
  }
  if (placementType) {
    query += `[data-purple-dot-placement-type="${placementType}"]`;
  }
  if (query === '') {
    throw new Error(
      "Invalid element query: ''. Must supply at least one of instanceId or placementType "
    );
  }
  return query;
}

export function getPlacementIframe({ instanceId, placementType }) {
  const container = getPlacementContainer({ instanceId, placementType });
  return placementIframe(container);
}

export function getPlacementIframes({ placementType }) {
  return getPlacementContainers({ placementType })
    .map((container) => placementIframe(container))
    .filter((ifr) => ifr);
}

function placementIframe(container) {
  return container?.querySelector('iframe.purple-dot-frame');
}
